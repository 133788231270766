
<!--保养单审批-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">保养单审批</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">返回</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="formList"
            :model="formList"
            label-width="120px"
            :rules="rules"
            label-position="left"
        >
          <el-form-item label="保养单名称" prop="name">
            <div>{{ formList.deviceName }}</div>
          </el-form-item>
          <el-form-item label="单号" prop="" style="max-width: 1000px">
            <div>{{ formList.name }}</div>
          </el-form-item>
          <el-form-item label="保养类型" prop="code">
            <div>{{ formList.type }}</div>
          </el-form-item>
          <el-form-item label="保养时间" prop="name">
            <div>{{ formList.startTime }}-{{formList.endTime}}</div>
          </el-form-item>
          <el-form-item label="提报人" prop="name">
            <div>{{ formList.cancelPerson }}</div>
          </el-form-item>
          <el-form-item label="取消保养原因" prop="name">
            <div class="item-list" >
              {{ formList.reason }}
            </div>
          </el-form-item>
          <el-form-item>
            <el-button plain  type="primary" class="submit-btn" @click="handleSubmit">驳回</el-button>
            <el-button  @click="handleSwitch" class="submit-btn" type="primary" >
              通过
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'
export default {
  name: "maintenanceApproval",
  data() {
    return {
      flag: null,
      hide: false,
      minute: 0,
      second: 0,
      formList: {},
      infoList: [],
      list: [],
      data: {
        imageUrls: []
      },
      remark: "",
      rules: {
        lubricationScore: [
          { required: true, message: "请输入润滑得分", trigger: "blur" },
          {
            pattern: /[0-9]\d*/,
            max: 60,
            message: "必须为正整数",
            trigger: "change",
          },
        ],
        cleanScore: [
          { required: true, message: "请输入检查、清洁得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: "必须为正整数",
            trigger: "change",
          },
        ],
        adjustScore: [
          { required: true, message: "请输入调节得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: "必须为正整数",
            trigger: "change",
          },
        ],
      },
    }
  },
  created() {
    this.updateDevice()
  },
  mounted () {
  },
  methods: {
    back() {
      this.$router.push(`PrintHome`)
    },
    updateDevice() {
      API.getMaintainDetail(this.$route.query.id).then(res => {
        console.log(res)
        this.formList = res.message.data
      })
    },
    lubricationScoreLimit (e, max, min) {
      this.formList.lubricationScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    adjustScoreLimit (e, max, min) {
      this.formList.adjustScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    cleanScoreLimit (e, max, min) {
      this.formList.cleanScore = this.limitInputNumber(e, max, min)
      this.$forceUpdate()
    },
    handleSubmit () {
      API.checkApproval({id:this.$route.query.id,state: 0}).then(res => {
        if (res.message.success === true) {
          this.$message({message: '操作成功',type: 'success',});
          this.$router.back();
        } else {
          this.$message({message: '操作失败',type: 'error',});
        }
      })
    },
    handleSwitch() {
      API.checkApproval({id:this.$route.query.id,state: 1}).then(res => {
        if (res.message.success === true) {
          this.$message({message: '操作成功',type: 'success',});
          this.$router.back();
        } else {
          this.$message({message: '操作失败',type: 'error',});
        }
      })

    },
    limitInputNumber (
        value,
        max = null,
        min = null,
        length = null,
        isBlur = false,
        isMoney = false
    ) {
      if (!value && value !== 0) {
        return min > 0 ? min : null
      }
      //是否需要保留小数
      value = value.toString()
      if (!length) {
        if (max < 0 || min < 0) {
          value = value.replace(/[^\-\d]+/g, "")
        } else {
          value = value.replace(/[^0-9]/gi, "")
        }
      } else {
        // 保留数字和小数点
        if (max < 0 || min < 0) {
          value = value.replace(/[^\-\d^\\.]+/g, "")
        } else {
          value = value.replace(/[^\d^\\.]+/g, "")
        }
        if (value.indexOf(".") > 0) {
          value = value.slice(0, value.indexOf(".") + (length + 1))
        } else if (value.indexOf(".") === 0 && value.length === 1) {
          value = 0 + value
        } else if (
            value.indexOf(".") === value.length - 1 &&
            value.length != 0 &&
            isBlur
        ) {
          value = value.substring(0, value.length - 1)
        }
      }
      if (max) {
        max = Number(max)
        let tempValue = Number(value)
        if (tempValue > max) {
          value = max
        }
      }
      if (min) {
        min = Number(min)
        let tempValue = Number(value)
        if (tempValue < min) {
          value = min
        }
      }
      if (isMoney) {
        for (var i = 0; i < value.length; i++) {
          if (value[i] === ".") {
            break
          }
          if (value[i] == 0 && value[i + 1] !== "." && i == 0) {
            value = value.slice(i + 1, value.length - 1)
            i--
          }
        }
      }
      if (isBlur) {
        return Number(value)
      } else {
        return value
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
.bofang {
  cursor: pointer;
}
.group {
  .item_img {
    //height: 100px;
    overflow: hidden;
    //line-height: 100px;
    //margin-bottom: 10px;
    text-align: center;
  }
}
</style>
